var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"homepage"}},[_c('header',{staticClass:"fixed-top d-flex align-items-center header-transparent",attrs:{"id":"header"}},[_c('site-header')],1),_c('Carousel',{attrs:{"titlePage":'Tra cứu thông tin người lao động'}}),_c('main',{attrs:{"id":"main"}},[_c('section',{staticClass:"team",attrs:{"data-aos":"fade-up","data-aos-easing":"ease-in-out","data-aos-duration":"500"}},[_c('div',{staticClass:"container",attrs:{"id":"legal-document"}},[_c('div',{staticClass:"d-flex"},[_c('feather-icon',{attrs:{"icon":"HomeIcon","size":"20","color":"#0282CD"}}),_c('feather-icon',{staticClass:"ml-1",attrs:{"icon":"ChevronRightIcon","size":"20"}}),_c('div',{staticClass:"titlePage",staticStyle:{"color":"#000000"}},[_vm._v(" Tra cứu ")]),_c('feather-icon',{staticClass:"ml-1",attrs:{"icon":"ChevronRightIcon","size":"20"}}),_c('div',{staticClass:"titlePage"},[_vm._v(" Tra cứu thông tin người lao động ")])],1),_c('div',{staticClass:"mt-1"},[_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"xl":"6","lg":"6","md":"6","sm":"12"}},[_c('v-select',{staticClass:"filter-search-worker",attrs:{"reduce":function (item) { return item.id; },"label":"name","options":_vm.dataComboboxBusiness,"placeholder":"Doanh nghiệp"},on:{"input":_vm.filterBusiness},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}])})],1),_c('b-col',{staticClass:"d-flex justify-content-end mb-1",attrs:{"xl":"6","lg":"6","md":"6","sm":"12"}},[_c('search',{staticClass:"search-input",on:{"change":_vm.search}})],1)],1),_c('vue-good-table',{ref:"user-table",attrs:{"style-class":"vgt-table","columns":_vm.columns,"rows":_vm.dataList || [],"select-options":{
              enabled: false,
              vertialAlignTop: true,
              selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: true, // disable the select info panel on top
              selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            }},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [(props.column.label ==='name')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" HỌ TÊN NGƯỜI LAO ĐỘNG ")]):(props.column.label ==='bussiness')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" DOANH NGHIỆP ")]):(props.column.label ==='country')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" QUỐC TỊCH ")]):(props.column.label ==='academicLevelString')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" TRÌNH ĐỘ HỌC VẤN ")]):(props.column.label ==='contractTypeString')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" LOẠI HỢP ĐỒNG ")]):(props.column.label ==='statusString')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" TRẠNG THÁI ")]):_vm._e()]}},{key:"table-row",fn:function(props){return [(props.column.field === 'name')?_c('span',[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.name))])]):(props.column.field === 'business')?_c('span',[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.business))])]):(props.column.field === 'country')?_c('span',[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.country))])]):(props.column.field === 'academicLevelString')?_c('span',[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.academicLevelString))])]):(props.column.field === 'contractTypeString')?_c('span',[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.contractTypeString))])]):(props.column.field === 'statusString')?_c('span',[_c('b-badge',{staticClass:"border-status",attrs:{"pill":"","variant":_vm.statusVariant(props.row.status)}},[_c('span',[_vm._v(" "+_vm._s(props.row.statusString))])])],1):_vm._e()]}}])}),_c('my-pagination',{attrs:{"totalItems":_vm.totalRecord,"currentPage":_vm.urlQuery.pageNumber},on:{"pageClick":_vm.pageChange}})],1)])])]),_c('footer',{attrs:{"id":"footer","data-aos":"fade-up","data-aos-easing":"ease-in-out","data-aos-duration":"500"}},[_c('site-footer')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }