<template>
  <div id="homepage">
    <!-- ======= Header ======= -->
    <header
      id="header"
      class="fixed-top d-flex align-items-center header-transparent"
    >
      <site-header />
    </header>
    <!-- End Header -->

    <!-- ======= Hero Section ======= -->
    <Carousel
      :titlePage="'Tra cứu thông tin người lao động'"
    />
    <!-- End Hero -->

    <main
      id="main"
    >
      <section
        class="team"
        data-aos="fade-up"
        data-aos-easing="ease-in-out"
        data-aos-duration="500"
      >
        <div
          id="legal-document"
          class="container"
        >
          <div class="d-flex">
            <feather-icon
              icon="HomeIcon"
              size="20"
              color="#0282CD"
            />
            <feather-icon
              icon="ChevronRightIcon"
              size="20"
              class="ml-1"
            />
            <div
              class="titlePage"
              style="color: #000000"
            >
              Tra cứu
            </div>
            <feather-icon
              icon="ChevronRightIcon"
              size="20"
              class="ml-1"
            />
            <div
              class="titlePage"
            >
              Tra cứu thông tin người lao động
            </div>
          </div>
          <div
            class="mt-1"
          >
            <b-row>
              <b-col
                xl="6"
                lg="6"
                md="6"
                sm="12"
                class="mb-1"
              >
                <v-select
                  :reduce="item => item.id"
                  label="name"
                  class="filter-search-worker"
                  :options="dataComboboxBusiness"
                  placeholder="Doanh nghiệp"
                  @input="filterBusiness"
                >
                  <template v-slot:no-options="{ searching }">
                    <template v-if="searching">
                      Không có kết quả.
                    </template>
                    <em
                      v-else
                    >Không có dữ liệu</em>
                  </template>
                </v-select>
              </b-col>
              <b-col
                xl="6"
                lg="6"
                md="6"
                sm="12"
                class="d-flex justify-content-end mb-1"
              >
                <search
                  class="search-input"
                  @change="search"
                />
              </b-col>
            </b-row>

            <vue-good-table
              ref="user-table"
              style-class="vgt-table"
              :columns="columns"
              :rows="dataList || []"
              :select-options="{
                enabled: false,
                vertialAlignTop: true,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
            >
              <!-- Slot: Table Column -->
              <template
                slot="table-column"
                slot-scope="props"
              >
                <span
                  v-if="props.column.label ==='name'"
                  class="text-nowrap"
                >
                  HỌ TÊN NGƯỜI LAO ĐỘNG
                </span>
                <span
                  v-else-if="props.column.label ==='bussiness'"
                  class="text-nowrap"
                >
                  DOANH NGHIỆP
                </span>
                <span
                  v-else-if="props.column.label ==='country'"
                  class="text-nowrap"
                >
                  QUỐC TỊCH
                </span>
                <span
                  v-else-if="props.column.label ==='academicLevelString'"
                  class="text-nowrap"
                >
                  TRÌNH ĐỘ HỌC VẤN
                </span>
                <span
                  v-else-if="props.column.label ==='contractTypeString'"
                  class="text-nowrap"
                >
                  LOẠI HỢP ĐỒNG
                </span>
                <span
                  v-else-if="props.column.label ==='statusString'"
                  class="text-nowrap"
                >
                  TRẠNG THÁI
                </span>
              </template>

              <!-- Slot: Table Row -->
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: số hiệu văn bản-->
                <span v-if="props.column.field === 'name'">
                  <span class="text-nowrap">{{ props.row.name }}</span>
                </span>

                <!-- Column: tên văn bản-->
                <span v-else-if="props.column.field === 'business'">
                  <span class="text-nowrap">{{ props.row.business }}</span>
                </span>

                <!-- Column: trích yếu -->
                <span v-else-if="props.column.field === 'country'">
                  <span class="text-nowrap">{{ props.row.country }}</span>
                </span>

                <span v-else-if="props.column.field === 'academicLevelString'">
                  <span class="text-nowrap">{{ props.row.academicLevelString }}</span>
                </span>

                <!-- Column: trích yếu -->
                <span v-else-if="props.column.field === 'contractTypeString'">
                  <span class="text-nowrap">{{ props.row.contractTypeString }}</span>
                </span>

                <span v-else-if="props.column.field === 'statusString'">
                  <b-badge
                    pill
                    :variant="statusVariant(props.row.status)"
                    class="border-status"
                  >
                    <span> {{ props.row.statusString }}</span>
                  </b-badge>
                </span>

                <!-- Column: trích yếu -->

              </template>
            </vue-good-table>

            <!-- pagination -->
            <my-pagination
              :totalItems="totalRecord"
              :currentPage="urlQuery.pageNumber"
              @pageClick="pageChange"
            />
          </div>
        </div>
      </section>
      <!-- End Team Section -->
    </main>
    <!-- End #main -->

    <!-- ======= Footer ======= -->
    <footer
      id="footer"
      data-aos="fade-up"
      data-aos-easing="ease-in-out"
      data-aos-duration="500"
    >
      <site-footer />

    </footer>
    <!-- End Footer -->

    <!-- <a
      href="#"
      class="back-to-top d-flex align-items-center justify-content-center"
    ><i class="bi bi-arrow-up-short"></i></a> -->
  </div>
</template>

<script>
import { formatFullName, formatDateToDDMM } from '@core/utils/filter'
import {
  BBadge,
  BMedia,
  BDropdown,
  BCol,
  BRow,
  BDropdownItem,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import ButtonComponent from '@/components/button/ButtonComponent.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import UserAvatar from '@/components/avatar/UserAvatar.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import Search from '@/components/search/Search.vue'
import Carousel from './components/Carousel.vue'
import SiteFooter from './components/SiteFooter.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from './constants/ConstantsApi'
import SiteHeader from './components/SiteHeader.vue'

export default {
  components: {
    VueGoodTable,
    BBadge,
    BMedia,
    MyPagination,
    UserAvatar,
    BDropdown,
    BDropdownItem,
    FeatherIcon,
    Search,
    ButtonComponent,
    ConfirmModal,
    BButton,
    BCol,
    BRow,
    SiteHeader,
    Carousel,
    VBTooltip,
    SiteFooter,
    vSelect,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  filters: {
    formatDateToDDMM,
  },
  data() {
    return {
      tokenCheck: localStorage.getItem('accessToken'),
      formatFullName,
      columns: [
        {
          label: 'name',
          field: 'name',
          sortable: false,
        },
        {
          label: 'bussiness',
          field: 'bussiness',
          sortable: false,
        },
        {
          label: 'country',
          field: 'country',
          sortable: false,
        },
        {
          label: 'academicLevelString',
          field: 'academicLevelString',
          sortable: false,
        },
        {
          label: 'contractTypeString',
          field: 'contractTypeString',
          sortable: false,
        },
        {
          label: 'statusString',
          field: 'statusString',
          sortable: false,
        },
      ],
      currentPage: 0,
      urlQuery: {
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      totalRecord: 0,
      isActive: false,
      userId: '',
      dataList: [],
      dataComboboxBusiness: [],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        NonLiscening: 'secondary',
        PendingLicensing: 'warning',
        Licensing: 'primary',
        RejectLicense: 'danger',
        PendingRenew: 'warning',
        PendingExtend: 'warning',
        Revoke: 'danger',
        Extend: 'success',
        Renew: 'primary',
      }
      return status => statusColor[status]
    },
  },
  mounted() {
    /* eslint-disable no-undef */
    /* eslint-disable no-new */
    // eslint-disable-next-line func-names
    /**
     * Easy selector helper function
     */
    const select = (el, all = false) => {
      el = el.trim()
      if (all) {
        return [...document.querySelectorAll(el)]
      }
      return document.querySelector(el)
    }

    /**
     * Easy event listener function
     */
    const on = (type, el, listener, all = false) => {
      const selectEl = select(el, all)
      if (selectEl) {
        if (all) {
          selectEl.forEach(e => e.addEventListener(type, listener))
        } else {
          selectEl.addEventListener(type, listener)
        }
      }
    }

    /**
     * Easy on scroll event listener
     */
    const onscroll = (el, listener) => {
      el.addEventListener('scroll', listener)
    }

    /**
     * Scrolls to an element with header offset
     */
    const scrollto = el => {
      const header = select('#header')
      let offset = header.offsetHeight

      if (!header.classList.contains('header-scrolled')) {
        offset -= 20
      }

      const elementPos = select(el).offsetTop
      window.scrollTo({
        top: elementPos - offset,
        behavior: 'smooth',
      })
    }

    /**
     * Toggle .header-scrolled class to #header when page is scrolled
     */
    const selectHeader = select('#header')
    if (selectHeader) {
      const headerScrolled = () => {
        if (window.scrollY > 100) {
          selectHeader.classList.add('header-scrolled')
        } else {
          selectHeader.classList.remove('header-scrolled')
        }
      }
      window.addEventListener('load', headerScrolled)
      onscroll(document, headerScrolled)
    }

    /**
     * Back to top button
     */
    const backtotop = select('.back-to-top')
    if (backtotop) {
      const toggleBacktotop = () => {
        if (window.scrollY > 100) {
          backtotop.classList.add('active')
        } else {
          backtotop.classList.remove('active')
        }
      }
      window.addEventListener('load', toggleBacktotop)
      onscroll(document, toggleBacktotop)
    }

    /**
     * Mobile nav toggle
     */
    // eslint-disable-next-line func-names
    on('click', '.mobile-nav-toggle', function (e) {
      select('#navbar').classList.toggle('navbar-mobile')
      this.classList.toggle('bi-list')
      this.classList.toggle('bi-x')
    })

    /**
     * Mobile nav dropdowns activate
     */
    // eslint-disable-next-line func-names
    on(
      'click',
      '.navbar .dropdown > a',
      // eslint-disable-next-line func-names
      function (e) {
        if (select('#navbar').classList.contains('navbar-mobile')) {
          e.preventDefault()
          this.nextElementSibling.classList.toggle('dropdown-active')
        }
      },
      true,
    )

    /**
     * Scrool with ofset on links with a class name .scrollto
     */
    // eslint-disable-next-line func-names
    on(
      'click',
      '.scrollto',
      // eslint-disable-next-line func-names
      function (e) {
        if (select(this.hash)) {
          e.preventDefault()

          const navbar = select('#navbar')
          if (navbar.classList.contains('navbar-mobile')) {
            navbar.classList.remove('navbar-mobile')
            const navbarToggle = select('.mobile-nav-toggle')
            navbarToggle.classList.toggle('bi-list')
            navbarToggle.classList.toggle('bi-x')
          }
          scrollto(this.hash)
        }
      },
      true,
    )

    /**
     * Skills animation
     */
    const skilsContent = select('.skills-content')
    if (skilsContent) {
      // eslint-disable-next-line no-new
      new Waypoint({
        element: skilsContent,
        offset: '80%',
        handler(direction) {
          const progress = select('.progress .progress-bar', true)
          progress.forEach(el => {
            el.style.width = `${el.getAttribute('aria-valuenow')}%`
          })
        },
      })
    }

    /**
     * Testimonials slider
     */
    // eslint-disable-next-line no-new
    new Swiper('.testimonials-carousel', {
      speed: 400,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
    })

    /**
     * Porfolio isotope and filter
     */
    window.addEventListener('load', () => {
      const portfolioContainer = select('.portfolio-container')
      if (portfolioContainer) {
        // eslint-disable-next-line no-undef
        const portfolioIsotope = new Isotope(portfolioContainer, {
          itemSelector: '.portfolio-wrap',
          layoutMode: 'fitRows',
        })

        const portfolioFilters = select('#portfolio-flters li', true)

        // eslint-disable-next-line func-names
        on(
          'click',
          '#portfolio-flters li',
          // eslint-disable-next-line func-names
          function (e) {
            e.preventDefault()
            portfolioFilters.forEach(el => {
              el.classList.remove('filter-active')
            })
            this.classList.add('filter-active')

            portfolioIsotope.arrange({
              filter: this.getAttribute('data-filter'),
            })
            portfolioIsotope.on('arrangeComplete', () => {
              // eslint-disable-next-line no-undef
              AOS.refresh()
            })
          },
          true,
        )
      }
    })

    /**
     * Initiate portfolio lightbox
     */
    // eslint-disable-next-line no-undef
    const portfolioLightbox = GLightbox({
      selector: '.portfolio-lightbox',
    })

    /**
     * Portfolio details slider
     */
    // eslint-disable-next-line no-new
    // eslint-disable-next-line no-undef
    new Swiper('.portfolio-details-slider', {
      speed: 400,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
    })

    /**
     * Animation on scroll
     */
    window.addEventListener('load', () => {
      // eslint-disable-next-line no-undef
      AOS.init({
        duration: 1000,
        easing: 'ease-in-out',
        once: true,
        mirror: false,
      })
    })
  },
  created() {
    this.fetchData(this.urlQuery)
    this.fetchCombobox()
  },
  methods: {
    // Fetch data
    async fetchData(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.WORKER, {
        params: urlQuery,
      })
      this.dataList = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },
    async fetchCombobox() {
      const { data } = await axiosApiInstance.get(ConstantsApi.COMBOBOX_BUSINESS)
      this.dataComboboxBusiness = data?.data
    },
    filterBusiness(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.bussinessId = val
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.bussinessId = ''
        this.fetchData(this.urlQuery)
      }
    },
    logout() {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      localStorage.removeItem('userData')
      localStorage.removeItem('accessToken')
      this.$ability.update(initialAbility)
      this.$router.push({ name: 'home' })
      this.$router.go()
    },
    // phân trang
    pageChange(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchData(this.urlQuery)
    },

    // Lấy danh sách khi tìm kiếm
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchData(this.urlQuery)
      }
    },
  },
}
</script>

<style lang="scss">

#main {
  .container {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
    margin-right: auto;
    margin-left: auto;
  }
}

#legal-document {
  .vgt-table {
    th {
      background-color: #0282CD;
      color: #fff !important;
    }
  }
  .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }

  .border-status {
    border-radius: 4px;
  }

  .button-icon {
    .btn-component {
      width: 38px;
      height: 38px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 11px;
    }
  }

  .button-icon-custom {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
  }

  .search-input {
    width: 400px;
  }

  .button-import {
    .input-file {
      display: none;
    }
  }

  .btn-icon-file {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
    label {
      margin-bottom: unset !important;
    }
  }
  .filter-search-worker{
    width: 400px;
  }
}

@media (max-width: 1024px){
  #legal-document {
  .search-input {
    width: 100%;
  }
  .filter-search-worker{
    width: 100%;
  }
}
}
</style>
